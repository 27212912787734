.scroll {
    scrollbar-color: var(--scroll-thumb-color, #3a8ace) var(--scroll-track, transparent) !important;
    scrollbar-width: thin !important;
}

.scroll::-webkit-scrollbar {
    width: var(--scroll-size, 10px) !important;
    height: var(--scroll-size, 10px) !important;
}

.scroll::-webkit-scrollbar-track {
    background-color: var(--scroll-track, transparent) !important;
    border-radius: var(--scroll-track-radius, var(--scroll-radius)) !important;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb-color, #3a8ace) !important;
    background-image: var(--scroll-thumb, none) !important;
    border-radius: var(--scroll-thumb-radius, var(--scroll-radius)) !important;
}

/* Firefox */
.scroll {
    scrollbar-color: var(--scroll-thumb-color, #3a8ace) var(--scroll-track, transparent) !important;
    scrollbar-width: thin !important;
}

.scroll::-moz-scrollbar {
    width: var(--scroll-size, 10px) !important;
    height: var(--scroll-size, 10px) !important;
}

.scroll::-moz-scrollbar-track {
    background-color: var(--scroll-track, transparent) !important;
    border-radius: var(--scroll-track-radius, var(--scroll-radius)) !important;
}

.scroll::-moz-scrollbar-thumb {
    background-color: var(--scroll-thumb-color, #3a8ace) !important;
    background-image: var(--scroll-thumb, none) !important;
    border-radius: var(--scroll-thumb-radius, var(--scroll-radius)) !important;
}

.scroll-onpc {
    --scroll-size: 4px;
    --scroll-radius: 0px;
    --scroll-track: rgba(33, 37, 41, 1);
    --scroll-thumb-color: rgb(13 110 253 / 90%);
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "\f104";
}

.select2-container.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
    display: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    line-height: 1.42857143;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    float: left;
    margin-top: 5px;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
    color: #fff;
    background: #0284c7;
    border: 1px solid #0284c7;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin: 5px 0 0 6px;
    padding: 0 6px;
}

.select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
    background: transparent;
    padding: 0 12px;
    height: 32px;
    line-height: 1.42857143;
    margin-top: 0;
    min-width: 5em;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 3px;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #999;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
    margin-top: 6px;
}

.select2-container[dir="rtl"] .select2-selection--single {
    padding-left: 24px;
    padding-right: 12px;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 0;
    padding-left: 0;
    text-align: right;
    /* 1 */
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 12px;
    right: auto;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
    margin-left: 0;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 0;
    margin-right: 6px;
}

.select2-container[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.block-options {
    padding-right: 1.625rem;
}

.list {
    padding-right: 0;
}

.list-activity > li {
    padding-right: 2rem !important;
}

.list-activity > li > i:first-child {
    right: 0 !important;
}
